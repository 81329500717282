import {useMemo, useState} from "react";
import Swal from "sweetalert2";

import {httpRequest} from "~/fetch/common/http-request";

import {
    MergeAnswer,
    MergeAnswerAddFileDownloadRequest,
    MergeAnswerFileUploadResponse,
    MergeAnswerFormCheckRequest,
    MergeAnswerFormCheckResponse,
    MergeAnswerRequest,
} from "./answer.interface";

// 지점 응답
const useMergeAnswer = (config: MergeAnswer) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return (isNone: "Y" | "N", fileName?: string, filePath?: string) => {
            return httpRequest<MergeAnswerRequest | null, null>({
                method: "POST",
                url: "/lotte/merge/manager/reg",
                data: config.info
                    ? config.info.mergeType === "EXCEL" || config.info.mergeType === "FILE"
                        ? {
                              ...config.info,
                              ...(isNone === "Y" && {
                                  noticeMergeFileDTOList: [
                                      {
                                          noticeMergeId: config.info.noticeMergeId,
                                          fileOriginName: null,
                                          fileStorePath: null,
                                          isNone,
                                      },
                                  ],
                              }),
                              ...(config.file !== null && {
                                  noticeMergeFileDTOList: [
                                      {
                                          noticeMergeId: config.info.noticeMergeId,
                                          fileOriginName: fileName ?? null,
                                          fileStorePath: filePath ?? null,
                                          isNone: isNone,
                                      },
                                  ],
                              }),
                          }
                        : {
                              ...config.info,
                          }
                    : null,
                timeout: 1000 * 60 * 5,
            });
        };
    }, [config.info]);

    const fileUpload = useMemo(() => {
        return httpRequest<{uploadFile: File | null}, MergeAnswerFileUploadResponse>({
            method: "POST",
            url: "/lotte/file/upload",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: {
                uploadFile: config.file,
            },
            timeout: 1000 * 60 * 5,
        });
    }, [config.file]);

    const submit = async (isNone: "Y" | "N", mergeType: "EXCEL" | "DATA" | "FILE") => {
        try {
            setIsLoading(true);
            if (mergeType === "EXCEL" || mergeType === "FILE") {
                // FILE
                if (isNone === "N") {
                    const fileInfo = await fileUpload.submit();
                    const result = await request(
                        isNone,
                        fileInfo.data.responseData.fileName,
                        fileInfo.data.responseData.filePath,
                    ).submit();
                } else {
                    const result = await request("Y").submit();
                }
            } else {
                // DATA
                await request("N").submit();
            }
        } catch (e) {
            setError(e);
            console.log(e);
            Swal.fire({
                text: "오류가 발생하였습니다. 다시 시도해주세요",
                confirmButtonText: "확인",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, isLoading, error];
};

// 취합 파일 양식 검사
const useMergeAnswerFormCheck = (config: MergeAnswerFormCheckRequest) => {
    const [response, setResponse] = useState<MergeAnswerFormCheckResponse | null>(null);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return (uploadFile: File | null) => {
            return httpRequest<MergeAnswerFormCheckRequest, MergeAnswerFormCheckResponse>({
                method: "POST",
                url: "/lotte/merge/manager/formCheck",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                data: {uploadFile, noticeMergeId: config.noticeMergeId},
                timeout: 1000 * 60 * 5,
            });
        };
    }, [config]);

    const submit = async (uploadFile: File | null) => {
        try {
            const res = await request(uploadFile).submit();
            setResponse(res.data);
            return res.data;
        } catch (error) {
            setError(error);
            console.log(error);
            return null;
        }
    };

    return [submit, response, error];
};

// 추가첨부 개별/압축 다운로드
const useMergeAnswerAddFileDownload = () => {
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return (id?: number, noticeMergeId?: number) => {
            return httpRequest<MergeAnswerAddFileDownloadRequest, Blob>({
                method: "GET",
                url: "/lotte/merge/addFileDownload",
                params: {
                    id: id,
                    noticeMergeId: noticeMergeId,
                },
                responseType: "blob",
                timeout: 1000 * 60 * 5,
            });
        };
    }, []);

    const submit = async (data: {id?: number; noticeMergeId?: number}) => {
        try {
            const response = await request(data.id, data.noticeMergeId).submit();
            const blob = new Blob([response.data], {type: response.headers["content-type"]});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;

            // 파일명 추출
            const contentType = response.headers["content-type"];
            let fileName = "downloaded_file";

            if (contentType) {
                const matches = /filename=([^;]*)/.exec(contentType);
                if (matches != null && matches[1]) {
                    fileName = decodeURIComponent(matches[1].trim().replace(/['"]/g, ""));

                    fileName = decodeURIComponent(fileName.replace(/\+/g, " "));
                }
            }

            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (e) {
            setError(e);
            console.log(e);
        }
    };

    return [submit, error];
};

export {useMergeAnswer, useMergeAnswerAddFileDownload, useMergeAnswerFormCheck};
//TODO noticeMergeFileDTOList 에서 isNone이 Y 면 해당없음 originFileName, Path 값은 null로
